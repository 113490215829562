export default [
  {
    path: 'compsearch',
    name: 'compsearch',
    component: () => import('@/views/registration-teams/CompetitionSearch.vue'),
    props: route => ({
      criteria: route.query.criteria,
      type: route.query.type && route.query.type.split(','),
      activity: route.query.activity && route.query.activity !== ''
        ? route.query.activity.split(',')
        : undefined,
      gender: route.query.gender,
      agemin: route.query.agemin && route.query.agemin !== '' ? parseInt(route.query.agemin) : undefined,
      agemax: route.query.agemax && route.query.agemax !== '' ? parseInt(route.query.agemax) : undefined,
      competitiontype: route.query.competitiontype && route.query.competitiontype !== ''
        ? route.query.competitiontype.split(',')
        : undefined,
      source: route.query.source,
    }),
    meta: {
      requiresAuth: false,
      requiresVerified: false,
      layout: 'DefaultNoHeading',
      gtm: {
        page_name: 'TODO',
      },
      title: 'Competition Finder – MySideline',
      metaTags: [
        {
          name: 'description',
          content: 'Search for a rugby league competition or association to register a team to.',
        },
        {
          name: 'og:description',
          content: 'Search for a rugby league competition or association to register a team to.',
        },
      ],
    },
  },
  {
    path: 'teamform',
    name: 'teamform',
    component: () => import('@/views/registration-teams/TeamRegistrationForm.vue'),
    meta: {
      layout: 'DefaultNoHeading',
      gtm: {
        page_name: 'TODO',
      },
      title: 'Team Registration Form – MySideline',
      metaTags: [
        {
          name: 'description',
          content: 'Search for a rugby league competition or association to register a team to.',
        },
        {
          name: 'og:description',
          content: 'Search for a rugby league competition or association to register a team to.',
        },
      ],
    },
  },
  {
    path: 'team-additional-questions',
    name: 'team-additional-questions',
    component: () => import('@/views/registration-teams/AdditionalQuestions.vue'),
    meta: {
      title: 'Additional Questions – MySideline',
    },
  },
  {
    path: 'teamaddon',
    name: 'teamaddon',
    component: () => import('@/views/registration-teams/ChooseAddon.vue'),
    meta: {
      title: 'Choose Addon – MySideline',
    },
  },
  {
    path: 'teampayment',
    name: 'teampayment',
    component: () => import('@/views/registration-teams/Payment.vue'),
    meta: {
      title: 'Payment – MySideline',
    },
  },
  {
    path: 'invitemembers',
    name: 'invitemembers',
    component: () => import('@/views/registration-teams/InviteMembers.vue'),
    props: route => ({
      teamId: parseInt(route.query.teamid),
      registered: route.query.registered === 'true',
    }),
    meta: {
      title: 'Invite Team Members – MySideline',
    },
  },
];
