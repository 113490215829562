export const endpoints = {
  CREATE_MEMBER: "/nrl/api/v1/portal/members",
  /*
   * @oas [post] /nrl/api/v1/portal/members
   * description: Create a new member profile to current user
   * parameters:
   *   - firstName {string} first name
   *   - lastName {string} last name
   *   - dob {string} birthday
   *   - gender {string} gender
   */
  getMembers: "/nrl/api/v1/portal/members", // get
  SEARCH_MEMBER: "/nrl/api/v1/portal/members/search", // post
  transactions: "/nrl/api/v1/portal/user/transactions/", // GET - Transactions by active user
  /*
   * @oas [post] /nrl/api/v1/portal/registration/clearance
   * description: Insert clearance
   * parameters:
   *   - memberId {number} member id
   *   - memberType {string} member type
   *   - toClubId {number} to club id
   *   - reason {string} reason
   */
  clearance: "/nrl/api/v1/portal/registration/clearance",
  voucher: {
    /*
     * @oas [post] /nrl/api/v1/portal/registration/nsw-voucher
     * description: NSW Voucher Balance Checker
     * parameters:
     *  - barcode {string} NSW Voucher Barcode
     *  - pin {string} NSW Voucher Pin (DDMM, Members DOB)
     * return: balance (number, cents)
     */
    activensw: "/nrl/api/v1/portal/registration/nsw-voucher", // activeKid
    statedge: "/nrl/api/v1/portal/registration/statedge-discount", // clubDiscount
    programsettings: "/nrl/api/v1/portal/registration/program-settings-discount", // program settings discount
  },
};

export const endpointParams = {
  /**
   * @return {string}
   */
  logo: (id) => `${process.env.VUE_APP_S3}/logos/resize/${id}.png`,
  profile: {
    // Update Member
    update: (id) => `/nrl/api/v1/portal/members/${id}`,
    // Update Member minor details
    updateMinor: (id) => `/nrl/api/v1/portal/members/${id}/minor`,
    //  Unlink Members
    unlink: (id) => `/nrl/api/v1/portal/unlink/${id}`,
    // Registration history by member ID
    registrationHistory: (id) => `/nrl/api/v1/portal/registrations/${id}`,
    // Clearances by member ID
    clearances: (id) => `/nrl/api/v1/portal/clearances/${id}`,
    // Player Stats
    playerStats: (id) => `/nrl/api/v1/portal/player-stats/${id}`,
    // Dispensation
    dispensation: "/nrl/api/v1/portal/registration/dispensation/add",
    // Accreditations
    accreditations: (id) => `/nrl/api/v1/portal/user/accreditations/${id}`,
  },
};

export default endpoints;
