<template>
  <div id="app">
    <!-- LOADING OVERLAY -->
    <div
      @click="OVERLAY(false)"
      v-if="overl"
      style="
        z-index: 100;
        position: fixed;
        top: 0px;
        left: 0px;
        opacity: 50%;
        background-color: black;
        width: 100vw;
        height: 100vh;
      "
    ></div>
    <LoadingLayout v-if="loading()" />
    <Header />
    <div class="flex-10 d-flex-column">
      <component :is="layout">
        <template slot="notifications">
          <div class="notifications-wrapper">
            <Alert
              :id="`alert_${index}`"
              v-for="(notification, index) in notifications"
              :key="index"
              :type="notification.type"
            >
              {{ notification.msg }}
            </Alert>
          </div>
        </template>
      </component>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LoadingLayout from "./layouts/LoadingLayout.vue";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import Alert from "@/components/card/Alert.vue";
import Rego from "@/views/registration/Rego.vue";
import Default from "@/layouts/Default.vue";
import DefaultFullWidth from "@/layouts/DefaultFullWidth.vue";
import DefaultNoHeading from "@/layouts/DefaultNoHeading.vue";
import { mergeMeta } from "@/utils/router";

export default {
  name: "app",
  components: {
    LoadingLayout,
    Default,
    DefaultFullWidth,
    DefaultNoHeading,
    Header,
    Footer,
    Alert,
    Rego,
  },
  computed: {
    ...mapState("views", ["notifications"]),
    layout() {
      return mergeMeta(this.$route).layout || "Default";
    },
    overl() {
      return this.$store.getters["root/rootOverlay"];
    },
  },
  methods: {
    ...mapMutations("root", ["OVERLAY"]),

    loading() {
      return this.$store.getters["root/rootLoading"];
    },
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 100%;
  }
}

html {
  background-color: #f6f6f6;
}
</style>
