<template>
  <div
    style="text-align:center;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:white;box-shadow:0px 2px 6px rgba(0, 0, 0, 0.06);padding:20px 16px 0px 16px;"
  >
    <div style="font-size:16px;font-family: 'RL2-Medium';color: #222222;">
      Your Play Rugby League Code
    </div>
    <div style="padding:20px 0 20px 0;font-size:20px;font-family: 'RL2-Medium';color: #222222;">
      {{ profile.reward }}
    </div>
    <button
      class="copycode"
      @click="copycode(profile.reward)"
      style="cursor:pointer;letter-spacing: 1px;padding-top:8px;padding-bottom:6px;width:311px;font-size:12px;font-weight:400;"
    >
      COPY CODE
    </button>
    <div
      style="width:311px;padding:20px 8px 0px 8px;font-size:14px;font-family: 'RL2-Medium';color: #222222;"
    >
      Enter this code on the ticketing website to get one free ticket to any NRL match.
    </div>
    <div
      @click="$emit('openSteps')"
      style="cursor:pointer;padding:20px 0 20px 0;font-size:14px;font-family: 'RL2-Medium';color: #098255;text-decoration:underline;"
    >
      Steps to use code
    </div>
  </div>
</template>

<script>
export default {
  props: ["profile"],

  methods: {
    copycode(message) {
      const clipboardData = navigator.clipboard || window.clipboardData || event.clipboardData;
      clipboardData.writeText(message);
      this.alertVisible = true;
    }
  },
  data() {
    return {
      alertVisible: false
    };
  }
};
</script>

<style>
.copycode {
  background-color: white;
  color: #444444;
  border-radius: 4px;
  border: 1px solid #dddddd;
}
/* .copycode:hover { border:1px solid #DDDDDD;
  background-color: #098255;
  color: white;
} */
/* .copycode:focus {
  background-color: #098255;
  color: white;
} */
.copycode:focus {
  background-color: white;
  color: #444444;
  border: 1px solid #098255;
}
.copycode:active {
  background-color: rgb(152, 228, 175);
  color: #444444;
  border: 1px solid #098255;
}
</style>
